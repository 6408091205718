﻿import { global, isEmpty, isUnDefined } from "../Common/saop-common";
import { SaopServicesCore } from "../Core/saop-services-core";
import { SaopWebGrid, SaopWebGridProperties, SaopWebDataTable } from "../Components/saop-grid";
import { SaopWebGridUnitOrgFilter } from "../Components/saop-grid-filter-UnitOrg";
import { SaopSelectMonthYear } from "../Components/saop-select-month-year";
import { SaopRegAttendanceAllEmployeesExcessHoursView } from "../REG/saop-regAttendanceAllEmployeesExcessHours-view";
import { SaopWfAction } from "../Core/saop-view-form";

enum ExcessHoursActionType {
    ConfirmMultiple = 0
    , DeleteMultiple = 1
    , PayoutMultiple = 2
    , UnlockMultiple = 3
    , ConcludeMultiple = 4
}

export class SaopRegAttendanceAllEmployeesExcessHoursGrid {
    public _libName: string;

    private _webgrid: SaopWebGrid;

    //filtri tabela
    private _selectOrgFilter: SaopWebGridUnitOrgFilter;
    public _saopSelectMonthYearFilter: SaopSelectMonthYear;

    //edit forma
    private regAttendanceAllEmployeesExcessHoursView: SaopRegAttendanceAllEmployeesExcessHoursView;

    private _tableId: string;
    private _refreshingAllEmployeesTable: boolean;
    private _tableDivId: string;

    constructor(libName: string = "") {
        this._libName = libName;
        this._refreshingAllEmployeesTable = false;
        this._tableDivId = "#attendanceAllEmployeesExcessHoursTableDiv";
        this._tableId = "tableREGAllEmployeesExcessHours";

        this._selectOrgFilter = new SaopWebGridUnitOrgFilter(libName, "");
        this._selectOrgFilter = new SaopWebGridUnitOrgFilter(libName, "");
        this._selectOrgFilter.filterZaposleniTableRows = this.filterZaposleniTableRows.bind(this);

        this._saopSelectMonthYearFilter = new SaopSelectMonthYear("", "#selectMesecLeto", this._libName + "._saopSelectMonthYear");
        this._saopSelectMonthYearFilter.afterMonthYearValuesChanged = this.afterSaopMonthYearValuesChanged.bind(this);
    }

    editRecord(year: number, month: number, employeeId: string, employeeName: string): void {
        this.regAttendanceAllEmployeesExcessHoursView.fullTitle = this.regAttendanceAllEmployeesExcessHoursView.translationService.translate("Obdelava viška ur: ") + " " + employeeId + " - " + employeeName;
        this.regAttendanceAllEmployeesExcessHoursView.showViewForm(SaopWfAction.wfaEditRecord, { leto: year, mesec: month, sifraZaposleni: employeeId });
    };

    onInitView(): void {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesExcessHoursGrid.onInitView");

        this.regAttendanceAllEmployeesExcessHoursView = new SaopRegAttendanceAllEmployeesExcessHoursView("#regAttendanceAllEmployeesExcessHoursView001", this._libName + ".regAttendanceAllEmployeesExcessHoursView");
        this.regAttendanceAllEmployeesExcessHoursView.refreshData = this.refreshAllEmployeesTable.bind(this);

        this.refreshAllEmployeesForm();

        this.setMultipleConfirmDenyButtonsEnableDisable();
    }

    initFilterControls(): void {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesExcessHoursGrid.initFilterControls");

        this.setEventBindings();

        this._selectOrgFilter.initFilterControls();

        this._saopSelectMonthYearFilter.init();
    }

    initWebGrid1() {
        let _this = this;

        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesExcessHoursGrid.initWebGrid1");
        let wgId = "#" + this._tableId;

        this._webgrid = new SaopWebGrid(this._libName + ".webGrid1");
        let gridProperties: SaopWebGridProperties;

        let dataTable: SaopWebDataTable;

        dataTable = {
            enabled: true
            , columnDefsSettings: [
                {
                    targets: 0
                    , checkboxes: { selectRow: true }
                    , render: function (data, type, row, meta) {
                        var table, tr;
                        table = document.getElementById("tableREGAllEmployeesExcessHours");
                        tr = table.getElementsByTagName("tr");

                        data = '<input type="checkbox" class="dt-checkboxes">'
                        let hidden = $(tr[meta.row + 1]).hasClass("element-hidden");
                        if (row[8].toLowerCase() === 'true') {
                            data = '<div style="width:13px;"></div>';
                        }
                        if (hidden) {
                            data = '<input type="checkbox" class="dt-checkboxes" disabled>';
                        }
                        return data;
                    }
                },
                {
                    targets: 8,
                    visible: false
                }
            ]
            , order: [[1, 'asc']]
        }

        gridProperties = {
            gridId: wgId
            , togglePanel: { enabled: false }
            , dataTable: dataTable
        };

        this._webgrid.init(gridProperties);


        let _toolBar = $("#grid-toolbar-processed");

        let _btnConfirmMultiple = _toolBar.find("#btnConfirmMultiple");
        _btnConfirmMultiple.off("click");
        _btnConfirmMultiple.on('click', function (row) {
            _this.MultipleRecordActionClick(ExcessHoursActionType.ConfirmMultiple);
            this.blur();
        });
        let _btnDeleteMultiple = _toolBar.find("#btnDeleteMultiple");
        _btnDeleteMultiple.off("click");
        _btnDeleteMultiple.on('click', function (row) {
            _this.MultipleRecordActionClick(ExcessHoursActionType.DeleteMultiple);
            this.blur();
        });
        let _btnPayoutMultiple = _toolBar.find("#btnPayoutMultiple");
        _btnPayoutMultiple.off("click");
        _btnPayoutMultiple.on('click', function (row) {
            _this.MultipleRecordActionClick(ExcessHoursActionType.PayoutMultiple);
            this.blur();
        });
        let _btnUnlockMultiple = _toolBar.find("#btnUnlockMultiple");
        _btnUnlockMultiple.off("click");
        _btnUnlockMultiple.on('click', function (row) {
            _this.MultipleRecordActionClick(ExcessHoursActionType.UnlockMultiple);
            this.blur();
        });
        let _btnConcludeMultiple = _toolBar.find("#btnConcludeMultiple");
        _btnConcludeMultiple.off("click");
        _btnConcludeMultiple.on('click', function (row) {
            _this.MultipleRecordActionClick(ExcessHoursActionType.ConcludeMultiple);
            this.blur();
        });


        $("body").off("change", "#tableREGAllEmployeesExcessHours tr th.dt-checkboxes-select-all input[type='checkbox']");
        $("body").on("change", "#tableREGAllEmployeesExcessHours tr th.dt-checkboxes-select-all input[type='checkbox']", function () {
            //_this.onClickUnselectHidden();
            _this.setMultipleConfirmDenyButtonsEnableDisable();
        });

        $("body").off("change", "#tableREGAllEmployeesExcessHours tr td.dt-checkboxes-cell input[type='checkbox'].dt-checkboxes");
        $("body").on("change", "#tableREGAllEmployeesExcessHours tr td.dt-checkboxes-cell input[type='checkbox'].dt-checkboxes", function () {
            _this.setMultipleConfirmDenyButtonsEnableDisable();
        });

        $(".AllEmployeesExcessHours_edit").off("click");
        $(".AllEmployeesExcessHours_edit").on('click', function (row) {
            global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesExcessHoursGrid.AllEmployeesExcessHours_edit.click");
            let _btnEdit = row.currentTarget;
            let btnEnabled = _btnEdit.getAttribute("data-enabled")!;
            if (btnEnabled) {
                let year = _btnEdit.getAttribute("data-year")!;
                let month = _btnEdit.getAttribute("data-month")!;
                let employeeId = _btnEdit.getAttribute("data-employee-id")!;
                let employeeName = _btnEdit.getAttribute("data-employee-name")!;

                _this.editRecord(Number(year), Number(month), employeeId, employeeName);
            }
        });  

        $("#attendanceAllEmployeesAnalysisTableRows>tr>td").off("click");
        $("#attendanceAllEmployeesAnalysisTableRows>tr>td").on('click', function (row) {
            global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesExcessHoursGrid.AllEmployeesExcessHours_edit.click");
            let _btnEdit = row.currentTarget;
            var btnEnabled = (_btnEdit.parentElement.getAttribute("data-enabled").toString().toLowerCase() == "true") && !_btnEdit.classList.contains("dt-checkboxes-cell");
            if (btnEnabled) {
                let year = _btnEdit.parentElement.getAttribute("data-year");
                let month = _btnEdit.parentElement.getAttribute("data-month");
                let employeeId = _btnEdit.parentElement.getAttribute("data-employee-id");
                let employeeName = _btnEdit.parentElement.getAttribute("data-employee-name");
                _this.editRecord(Number(year), Number(month), employeeId, employeeName);
            }
        });  
    }

    MultipleRecordActionClick(type: ExcessHoursActionType): void {
        let _this = this;

        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesExcessHoursGrid.MultipleRecordActionClick");

        var rows = this._webgrid.dataTable.column(0).data().toArray();

        rows.forEach(function (_el) {
            var _row = _this._webgrid.dataTable.row(Number(_el)).node() as Element; 
            var checked = $($(_row).find('input[type="checkbox"]')[0]).prop('checked');
            if (typeof _row !== 'undefined' && checked == true) {
                var year = _row.getAttribute("data-year");
                var month = _row.getAttribute("data-month");
                var employeeId = _row.getAttribute("data-employee-id");
                var fingerprint = _row.getAttribute("data-fingerprint");
                console.log(year, month, employeeId, type);
                _this.confirmRecord(year, month, employeeId, fingerprint, type);
            }
        });
    }

    confirmRecord(year: string, month: string, employeeId: string, fingerprint: string, type: ExcessHoursActionType): void {
        global.ConsoleLogDegug("LIKVCakalnaVrstaGrid.confirmRecord");

        let _this = this;

        let _data = { leto: year, mesec: month, sifraZaposleni: employeeId };

        let _urlAction = "";
        switch (type) {
            case ExcessHoursActionType.ConfirmMultiple:
                _urlAction = "REGAttendanceAllEmployeesExcessHours/ConfirmMultiple";
                break;
            case ExcessHoursActionType.DeleteMultiple:
                _urlAction = "REGAttendanceAllEmployeesExcessHours/DeleteMultiple";
                break;
            case ExcessHoursActionType.PayoutMultiple:
                _urlAction = "REGAttendanceAllEmployeesExcessHours/PayoutMultiple";
                break;
            case ExcessHoursActionType.UnlockMultiple:
                _urlAction = "REGAttendanceAllEmployeesExcessHours/UnlockMultiple";
                break;
            case ExcessHoursActionType.ConcludeMultiple:
                _urlAction = "REGAttendanceAllEmployeesExcessHours/ConcludeMultiple";
                break;
            default:
                break;
        }
        
        let _saopServicesCore = new SaopServicesCore();
        this._refreshingAllEmployeesTable = true;
        _saopServicesCore.afterDone = function (response) {
            $(_this._tableDivId).html(response);

            _this.initWebGrid1();

            _this._refreshingAllEmployeesTable = false;

            let izbraniZaposleni = <string[]>(_this._selectOrgFilter._selectZaposleni.selectedCB.val());
            _this._selectOrgFilter._selectZaposleni.setSelectValues(izbraniZaposleni);
            _this._saopSelectMonthYearFilter.setRefreshingRecordsComplete();
        };
        _saopServicesCore.ExecuteAction({
            action: _urlAction
            , data: _data
            , type: "POST"
            , spinnerDivId: "#attendanceAllEmployeesExcessHoursLoading"
        });
    }


    refreshAllEmployeesTable(): void {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesExcessHoursGrid.refreshAllEmployeesTable");
        
        let _this = this;
        let saopServicesCore = new SaopServicesCore();

        let filter_values = this._saopSelectMonthYearFilter.getSelectedMonthYear();
        let params = { leto: filter_values.leto, mesec: filter_values.mesec };
        this._refreshingAllEmployeesTable = true;

        saopServicesCore.afterDone = function (response) {
            $(_this._tableDivId).html(response);

            _this.initWebGrid1();

            _this._refreshingAllEmployeesTable = false;
            //_this.enableDisableObdobjeFilters(_this._refreshingAllEmployeesTable);

            // izvedemo se filter vrstic tabele glede oznacenih zaposlenih
            let izbraniZaposleni = <string[]>(_this._selectOrgFilter._selectZaposleni.selectedCB.val());
            _this._selectOrgFilter._selectZaposleni.setSelectValues(izbraniZaposleni);
            _this._saopSelectMonthYearFilter.setRefreshingRecordsComplete();
            _this.setMultipleConfirmDenyButtonsEnableDisable();
            _this.setEventBindings();

        };
        saopServicesCore.ExecuteAction({
            action: "REGAttendanceAllEmployeesExcessHours/GetAllEmployeesExcessHoursTable"
            , data: params
            , type: "GET"
            , spinnerDivId: "#attendanceAllEmployeesExcessHoursLoading"
        });    
    }

    refreshAllEmployeesForm(params: any = null): void {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesExcessHoursGrid.refreshAllEmployeesForm");

        let _this = this;
        let saopServicesCore = new SaopServicesCore();

        saopServicesCore.afterDone = function (response) {
            $("#attendanceAllEmployeesExcessHoursForm").html(response);
            _this.initFilterControls();
            _this.bindGridEvents();
            _this.setEventBindings();

        };
        saopServicesCore.ExecuteAction({
            action: "REGAttendanceAllEmployeesExcessHours/GetAllEmployeesExcessHoursForm"
            , data: params
            , type: "GET"
            , spinnerDivId: "#attendanceAllEmployeesExcessHoursLoading"
        });    
    }

    bindGridEvents(): void {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesExcessHoursGrid.bindGridEvents");

        this.initWebGrid1();
    }

    filterZaposleniTableRows(filterZaposleni: string) {
        //filtriranje glede na organizacijsko enoto in zaposlenega
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesExcessHoursGrid.filterZaposleniTableRows");

        // Declare variables
        var table, tr, _rowId, i, txtValue;
        table = document.getElementById(this._tableId);
        tr = table.getElementsByTagName("tr");
        // Loop through all table rows, and hide those who don't match the search query
        for (i = 0; i < tr.length; i++) {
            _rowId = null;
            let _trId = $(tr[i]).attr("data-employee-id");
            if (!isUnDefined(_trId)) { 
                _rowId = _trId.toString()
            }

            if (_rowId) {
                txtValue = _rowId.toUpperCase();
                let defined = $(tr[i])[0].children[0].children.length;
                if (isEmpty(filterZaposleni)) {
                    $(tr[i]).addClass("element-hidden");
                    if (defined == 1)
                        $(tr[i])[0].children[0].innerHTML = '<input type="checkbox" class="dt-checkboxes" disabled>';
                } else if (filterZaposleni.toUpperCase().indexOf(txtValue) > -1) {
                    if (defined == 1 && $(tr[i]).hasClass("element-hidden")) {
                        $(tr[i])[0].children[0].innerHTML = '<input type="checkbox" class="dt-checkboxes">';
                        //if ($(tr[0])[0].children[0].checked == true)
                        //    console.log("checked");

                        //$(tr[0])[0].children[0].indeterminate = true;
                        $(tr[0])[0].children[0]
                    }
                    $(tr[i]).removeClass("element-hidden");
                    
                } else {
                    $(tr[i]).addClass("element-hidden");
                    if (defined == 1)
                        $(tr[i])[0].children[0].innerHTML = '<input type="checkbox" class="dt-checkboxes" disabled>';
                }
            }
        }
    }

    public afterSaopMonthYearValuesChanged(leto: number, mesec: number): void {
        //pomikanje po mesecih, naprej nazaj, sprememba meseca....
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesExcessHoursGrid.afterSaopMonthYearValuesChanged");

        this.refreshAllEmployeesTable();
    }

    setMultipleConfirmDenyButtonsEnableDisable(): void {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesExcessHoursGrid.setMultipleConfirmDenyButtonsEnableDisable");

        let _toolBar = $("#grid-toolbar-processed");

        let _btnConfirmMultiple = _toolBar.find("#btnConfirmMultiple");
        _btnConfirmMultiple.prop('disabled', true);
        let _btnDeleteMultiple = _toolBar.find("#btnDeleteMultiple");
        _btnDeleteMultiple.prop('disabled', true);
        let _btnPayoutMultiple = _toolBar.find("#btnPayoutMultiple");
        _btnPayoutMultiple.prop('disabled', true);
        let _btnUnlockMultiple = _toolBar.find("#btnUnlockMultiple");
        _btnUnlockMultiple.prop('disabled', true);
        let _btnConcludeMultiple = _toolBar.find("#btnConcludeMultiple");
        _btnConcludeMultiple.prop('disabled', true);

        $("#tableREGAllEmployeesExcessHours tr td.dt-checkboxes-cell input[type='checkbox'].dt-checkboxes").each(function () {
            let checked = $(this).is(":checked");
            if (checked)
            {
                _btnConfirmMultiple.prop('disabled', false);
                _btnDeleteMultiple.prop('disabled', false);
                _btnPayoutMultiple.prop('disabled', false);
                _btnUnlockMultiple.prop('disabled', false);
                _btnConcludeMultiple.prop('disabled', false);
            }
        });
    }
    
    public setEventBindings(): void {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesExcessHoursGrid.setEventBindings");

        let _this = this;

        _this.setTableHeight();
        window.onresize = function () {
            _this.setTableHeight();
        };

    }

    public setTableHeight(): void {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesExcessHoursGrid.setTableHeight");

        let _height = $('#grid-toolbar-processed').height();
        let remaining_height = window.innerHeight - _height - 245;
        $('#tableREGAllEmployeesExcessHours_wrapper').height(remaining_height);
    }
}

