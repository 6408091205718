import { global, setModalFormZIndex, getRelativeSiteURL, getSiteAbsoluteURL, isUnDefined } from "../Common/saop-common";
import { SaopWebGrid,SaopWebGridProperties,SaopWebGridToolbar,SaopWebGridToolbarButton,SaopWebDataTable} from "../Components/saop-grid";
import { SaopServicesCore } from "../Core/saop-services-core";
import { LIKVCakalnaVrstaView } from "../LIKV/LIKVCakalnaVrsta-view";
import { SaopWfAction } from "../Core/saop-view-form";
import { LIKVCakalnaVrstaComment } from "../LIKV/LIKVCakalnaVrsta-Comment";
import { LIKVCakalnaVrstaCommentConfirm } from "../LIKV/LIKVCakalnaVrsta-CommentConfirm";
import { SaopMultiselect } from "../Components/saop-multiselect";
import { SaopPopover } from "../Components/saop-popover";
import { DataTables } from "../@types/data-tables/types";

export class LIKVCakalnaVrstaGrid {
    private libName: string;
    private _saopServicesCore: SaopServicesCore;
    private _mainFormDiv: JQuery<HTMLElement>;
    private _searchValue: JQuery<HTMLElement>;
    private _searchValue1: JQuery<HTMLElement>;
    private _selectedRowNo: number;
    private _prevIDVrste: string;
    private _prevID: string;    
    private _nextIDVrste: string;
    private _nextID: string;
    private _likvCakalnaVrstaComment: LIKVCakalnaVrstaComment;
    private _likvCakalnaVrstaCommentConfirm: LIKVCakalnaVrstaCommentConfirm;
    //
    public webGrid1: SaopWebGrid;
    public _LIKVCakalnaVrstaView: LIKVCakalnaVrstaView;
    private _vrstaDokumenta: SaopMultiselect;
    private _vrstaDokumentaMobile: SaopMultiselect;
    private _vrstaDokumentaDivId: string;
    public _dashboard: boolean = false;
    private _saopPopoverFilter: SaopPopover;
    private _izbranaVrstaDokumenta: string;

    constructor(libName: string) {
        this.libName = libName;
        this._saopServicesCore = new SaopServicesCore();
        //
        this._LIKVCakalnaVrstaView = new LIKVCakalnaVrstaView("#LIKVCakalnaVrstaView001", this.libName + "._LIKVCakalnaVrstaView");
        this._LIKVCakalnaVrstaView.refreshData = this.onLIKVidDocumentComplete.bind(this);
        this._LIKVCakalnaVrstaView.fullScreen = true;
        this._LIKVCakalnaVrstaView.MoveToNext = this.MoveToNextExt.bind(this);
        this._LIKVCakalnaVrstaView.MoveToPrevious = this.MoveToPreviousExt.bind(this);
        this._LIKVCakalnaVrstaView.ConfirmAndMoveToNext = this.ConfirmAndMoveToNextExt.bind(this);
        this._LIKVCakalnaVrstaView.DenyAndMoveToNext = this.DenyAndMoveToNextExt.bind(this);
        this._likvCakalnaVrstaComment = new LIKVCakalnaVrstaComment("#LIKVCakalnaVrstaView002", libName);
        this._likvCakalnaVrstaCommentConfirm = new LIKVCakalnaVrstaCommentConfirm("#LIKVCakalnaVrstaView003", libName);
        //        this._likvCakalnaVrstaComment.onDenyClick = this.onDenyClick.bind(this);
        //        this._vrstaDokumentaDivId = "#VrstaDokumentaDiv";  
        this._saopPopoverFilter = new SaopPopover();
        this._saopPopoverFilter.afterPopoverShow = this.afterPopoverMobileFilterShow.bind(this);
    }

    initView() {
        global.ConsoleLogDegug("LIKVCakalnaVrstaGrid.initView");
        this._nextIDVrste = '';
        this._nextID = '';
        this._mainFormDiv = $("#LIKVCakalnaVrstaGridBrowseForm");
        this.initWebGrid1();
        this.initWebGrid1Listners();
        this._searchValue = this._mainFormDiv.find("#SearchValue");
        this._searchValue1 = this._mainFormDiv.find("#SearchValue1");
        this.initFilterControls();
        //        this.initVrstaDokumenta();    
        this._saopPopoverFilter.bindPopoverEvents();
        this.setGlobalConfirmDenyButtonsEnableDisable();
        this._mainFormDiv.show();
        this.setMobileView();
        this.setCheckboxEnabledDisabled();
        this.editRowFromDashboard();
    }

    initWebGrid1() {
        global.ConsoleLogDegug("LIKVCakalnaVrstaGrid.");
        let wgId = "#LIKVCakalnaVrstaGrid";
        let captionToolbar = $(wgId).attr("caption-toolbar");
        let toolbarItemsTemplateId = $(wgId).attr("toolbar-items-templateId");
        //
        this.webGrid1 = new SaopWebGrid(this.libName + ".webGrid1");
        let gridProperties: SaopWebGridProperties;
        let gridToolbar: SaopWebGridToolbar;
        let toolbarButtons: SaopWebGridToolbarButton[] = [];
        let dataTable: SaopWebDataTable;
        //
        if (!this._dashboard) {
            gridToolbar = { id: "saopWebGridTB1", caption: captionToolbar, buttons: toolbarButtons, itemsTemplateId: toolbarItemsTemplateId };

            dataTable = { enabled: true, columnDefsSettings: [{ targets: 0, checkboxes: { selectRow: true } }],colSettings:true };
        }
        gridProperties = {
            gridId: wgId
            , togglePanel: { enabled: false }
            , toolbar: gridToolbar
            , dataTable: dataTable
        };
        //
        this.webGrid1.init(gridProperties);
        if (this._dashboard) {
            $(wgId + "_wrapper").addClass("saop-table-dashboard saop-table-dashboard-odsotnost");
            $(wgId + "_wrapper").css('overflow-x', 'hidden');
        }
        else {
            $(wgId + "_wrapper").css('max-height', 'calc(100vh - 350px)');
        }
    }

    public MoveToNextExt(close: boolean = false): void {
        global.ConsoleLogDegug("LIKVCakalnaVrstaGrid.MoveToNextExt");

        let _idVrste = this._nextIDVrste;
        let _id = this._nextID
        this.StorePrevAndNextRowData(this._nextIDVrste, this._nextID);
        let _row = $("tr[data-IDVrste='" + _idVrste + "']").filter("tr[data-ID='" + _id + "']");

        if (_row.length > 0) {
            this._selectedRowNo = _row.index(); // here we store the ID of the current row            
            //
            let _idVrste = _row.attr("data-idvrste");
            let _id = _row.attr("data-id");
            let _vrstaDokumenta = _row.attr("data-vrstadokumenta");
            let _dokument = _row.attr("data-dokument");
            //
            this.webGrid1.markSelectedRow(<HTMLElement>(_row)[0]);
            this.editRecord(_vrstaDokumenta, _dokument, _idVrste, _id);
        } else if (close) {
            this._LIKVCakalnaVrstaView.CloseViewForm();
        }
    }

    public MoveToPreviousExt(): void {
        global.ConsoleLogDegug("LIKVCakalnaVrstaGrid.");

        let _idVrste = this._prevIDVrste;
        let _id = this._prevID
        this.StorePrevAndNextRowData(this._prevIDVrste, this._prevID);

        let _row = $("tr[data-IDVrste='" + _idVrste + "']").filter("tr[data-ID='" + _id + "']");

        if (_row.length > 0) {
            this._selectedRowNo = _row.index(); // here we store the ID of the current row            
            //
            let _idVrste = _row.attr("data-idvrste");
            let _id = _row.attr("data-id");
            let _vrstaDokumenta = _row.attr("data-vrstadokumenta");
            let _dokument = _row.attr("data-dokument");
            //
            this.webGrid1.markSelectedRow(<HTMLElement>(_row)[0]);
            this.editRecord(_vrstaDokumenta, _dokument, _idVrste, _id);
        } 
    }

    public ConfirmAndMoveToNextExt(idVrste: string, id: string, komentar: string, idAkcije: string): void {
        global.ConsoleLogDegug("LIKVCakalnaVrstaGrid.ConfirmAndMoveToNextExt");
        this.confirmRecordAndNext(idVrste, id, komentar, idAkcije);
    }

    public DenyAndMoveToNextExt(idVrste: string, id: string, komentar: string, idAkcije: string): void {
        global.ConsoleLogDegug("LIKVCakalnaVrstaGrid.");
        this._likvCakalnaVrstaComment.onDenyClick = this.onDenyAndNext.bind(this);
        let _result = this._likvCakalnaVrstaComment.editRecord({});
    }

    initWebGrid1Listners() {
        global.ConsoleLogDegug("LIKVCakalnaVrstaGrid.initWebGrid1Listners");
        let _this = this;
        if (!this._dashboard) {
            $("body").off("click", "#LIKVCakalnaVrstaGrid td:not(.td-action)");
            $("body").on("click", "#LIKVCakalnaVrstaGrid td:not(.td-action)", function () {
                 let _i = $(this).closest('tr').index();
                 _this._selectedRowNo = _i; // here we store the ID of the current row
                // let _rows = $('#LIKVCakalnaVrstaGrid > tbody > tr');
                // let _row = $('#LIKVCakalnaVrstaGrid > tbody > tr').eq(_i);
                // let _idVrsteTest = _row[0].attributes[1].nodeValue;
                var data = $(this).closest("tr");
                let _idVrste = data.attr("data-IDVrste");
                let _id = data.attr("data-ID");
                let _vrstaDokumenta = data.attr("data-vrstadokumenta");
                let _dokument = data.attr("data-dokument");

                if (!isUnDefined(_idVrste) && !isUnDefined(_id)) {
                    // barvanje oznacene vrstrice
                    _this.webGrid1.markSelectedRow(<HTMLElement>(data)[0]);

                    _this.editRecord(_vrstaDokumenta, _dokument, _idVrste, _id);
                }
            });

            $("body").off("change", "#LIKVCakalnaVrstaGrid tr th.dt-checkboxes-select-all input[type='checkbox']");
            $("body").on("change", "#LIKVCakalnaVrstaGrid tr th.dt-checkboxes-select-all input[type='checkbox']", function () {
                _this.setGlobalConfirmDenyButtonsEnableDisable();
            });

            $("body").off("change", "#LIKVCakalnaVrstaGrid tr td.dt-checkboxes-cell input[type='checkbox'].dt-checkboxes");
            $("body").on("change", "#LIKVCakalnaVrstaGrid tr td.dt-checkboxes-cell input[type='checkbox'].dt-checkboxes", function () {
                _this.setGlobalConfirmDenyButtonsEnableDisable();
            });
        }

        if (this._dashboard) {
            $("body").off("click", "#LIKVCakalnaVrstaGrid td:not(.td-action)");
            $("body").on("click", "#LIKVCakalnaVrstaGrid td:not(.td-action)", function () {
                let _i = $(this).closest('tr').index();
                _this._selectedRowNo = _i; // here we store the ID of the current row
                //poberemo podatke
                var data = $(this).closest("tr");
                let _idVrste = data.attr("data-IDVrste");
                let _id = data.attr("data-ID");

                if (!isUnDefined(_idVrste) && !isUnDefined(_id)) {
                    //naredimo redirect na kontroler s parametri idvrste in id
                    let _applicationCode = this._izbranaVrstaDokumenta;
                    let _year = 0
                    let _controller = "LikvConfirmations";
                    let _action = "Index";
                    let _relativeURL = _controller + "/" + _action + "?year =" + _year + "&applicationCode" + _applicationCode + "&idVrste=" + _idVrste + "&id=" + _id;
                    let _url = getSiteAbsoluteURL(_relativeURL);
                    window.location.href = _url;
                    //window.location.href = "../"+_controller+"/"+_action+"?year =" + _year + "&applicationCode" + _applicationCode +  "&idVrste="+_idVrste+"&id="+_id;
                }
            });
        }

        $(".LIKVCakalnaVrstaGrid_deny").off("click");
        $(".LIKVCakalnaVrstaGrid_deny").on('click', function (row) {
            global.ConsoleLogDegug("LIKVCakalnaVrstaGrid_deny.click");
            //$('#detailsOPNPotniNalogiForm').remove();
            let _i = $(this).closest('tr').index();
            if (_this._selectedRowNo == null)
                _this._selectedRowNo = _i; // here we store the ID of the current row                          
            let _btnEdit = row.currentTarget;
            let btnEnabled = _btnEdit.getAttribute("data-enabled");
            if (btnEnabled.toString().toLowerCase() == "true") {
                _this._likvCakalnaVrstaComment.onDenyClick = _this.onDenyClick.bind(_this);
                let _result = _this._likvCakalnaVrstaComment.editRecord({});
                //     let IDVrste = _btnEdit.getAttribute("data-IDVrste");
                //     let ID = _btnEdit.getAttribute("data-ID");
                //     _this.denyRecord(IDVrste,ID);
            }
        });

        $(".LIKVCakalnaVrstaGrid_confirm").off("click");
        $(".LIKVCakalnaVrstaGrid_confirm").on('click', function (row) {
            global.ConsoleLogDegug("LIKVCakalnaVrstaGrid_confirm.click");
            //$('#detailsOPNPotniNalogiForm').remove();
            let _i = $(this).closest('tr').index();
            if (_this._selectedRowNo == null)
                _this._selectedRowNo = _i; // here we store the ID of the current row              
            let _btnDelete = row.currentTarget;
            let btnEnabled = _btnDelete.getAttribute("data-enabled");
            if (btnEnabled.toString().toLowerCase() == "true") {
                let IDVrste = _btnDelete.getAttribute("data-IDVrste");
                let ID = _btnDelete.getAttribute("data-ID");
                let komentar = "";
                let IDAkcije = "-1";
                _this.confirmRecord(IDVrste, ID, komentar, IDAkcije);
            }
        });

        if (!this._dashboard) {
            let _btnConfirmGlobal = this._mainFormDiv.find("#btnConfirmGlobal");
            _btnConfirmGlobal.off("click");
            _btnConfirmGlobal.on('click', function (row) {
                global.ConsoleLogDegug("btnConfirmGlobal.click");
                // let rows_selected = _this.webGrid1.getSelectedRows();
                // rows_selected.forEach(_el => {
                //   //console.log(_el);
                //   let _row = $('#LIKVCakalnaVrstaGrid > tbody > tr').eq(Number(_el)); // here we extrapolate the row data of selected index
                //   if (_row.length > 0) {
                //     //
                //     let _idVrste = _row.attr("data-idvrste");
                //     let _id = _row.attr("data-id"); 
                //     let _idAkcije = _row.attr("data-idAkcije");                   
                //     //
                //     //console.log(_idVrste);
                //     _this.confirmRecord(_idVrste, _id, _idAkcije);
                //   }

                // });
                _this._likvCakalnaVrstaCommentConfirm.onConfirmClick = _this.onConfirmGlobalClick.bind(_this);
                let _result = _this._likvCakalnaVrstaCommentConfirm.editRecord({});
            });

            let _btnDenyGlobal = this._mainFormDiv.find("#btnDenyGlobal");
            _btnDenyGlobal.off("click");
            _btnDenyGlobal.on('click', function (row) {
                global.ConsoleLogDegug("btnDenyGlobal.click");
                _this._likvCakalnaVrstaComment.onDenyClick = _this.onDenyGlobalClick.bind(_this);
                let _result = _this._likvCakalnaVrstaComment.editRecord({});
            });

            let _btnSwitchSignees = this._mainFormDiv.find("#btnSwitchSignees");
            _btnSwitchSignees.off("click");
            _btnSwitchSignees.on('click', function (row) {
                global.ConsoleLogDegug("btnSwitchSignees.click");
                _this.switchSignees();
            });

            let _applicationFilter = this._mainFormDiv.find("#ApplicationFilter");
            if (_applicationFilter.length > 0) {
                _applicationFilter.off("change");
                _applicationFilter.on("change", _this.onApplicationFilterChange.bind(_this));
            }
        }
        //
    }

    confirmRecord(idVrste: string, id: string, komentar: string, idAkcije: string, closeCommentView: boolean = false): void {
        global.ConsoleLogDegug("LIKVCakalnaVrstaGrid.confirmRecord");
        let _this = this;
        //
        let _idVrste = idVrste;
        let _id = id;
        let _confirmationStatus = 0;
        let _komentar = komentar;
        let _idAkcije = idAkcije;
        //
        let _data = { idVrste: _idVrste, id: _id, confirmationStatus: _confirmationStatus, komentar: _komentar, idAkcije: _idAkcije, caller:"Grid" };
        //
        let _urlAction = "";
        _urlAction = "LikvConfirmations/SetConfirmationstatusGrid";
        //
        let _saopServicesCore = new SaopServicesCore();
        _saopServicesCore.afterDone = function (response) {
            if (response.alertMessage) {
                alertify.message(response.alertMessage);
            }

            if (response.viewContent) {
                $("#messageBannerGrid").html(response.viewContent);
            }
            if (closeCommentView)
                _this._likvCakalnaVrstaCommentConfirm.CloseViewForm();

            _this.refreshGridData(0);

        };
        _saopServicesCore.ExecuteAction({
            action: _urlAction
            , data: _data
            , type: "POST"
        });
    }

    confirmRecordAndNext(idVrste: string, id: string, komentar: string, idAkcije: string): void {
        global.ConsoleLogDegug("LIKVCakalnaVrstaGrid.confirmRecordAndNext");
        let _this = this;
        //
        let _idVrste = idVrste;
        let _id = id;
        let _confirmationStatus = 0;
        let _komentar = komentar;
        let _idAkcije = idAkcije;

        let _data = { idVrste: _idVrste, id: _id, confirmationStatus: _confirmationStatus, komentar: _komentar, idAkcije: _idAkcije, caller:"Edit" };
        //
        let _urlAction = "";
        _urlAction = "LikvConfirmations/SetConfirmationstatusGrid";
        //
        let _saopServicesCore = new SaopServicesCore();
        _saopServicesCore.afterDone = function (response) {
            if (response.alertMessage) {
                alertify.message(response.alertMessage);
            }

            if (response.viewContent) {
                $("#messageBannerEdit").html(response.viewContent);
            }

            if (response.success == true)
                _this.MoveToNextExt(true);

            _this.refreshGridData(0);

        };
        _saopServicesCore.ExecuteAction({
            action: _urlAction
            , data: _data
            , type: "POST"
        });
    }

    denyRecord(idVrste: string, id: string, komentar: string, idAkcije: string): void {
        global.ConsoleLogDegug("LIKVCakalnaVrstaGrid.denyRecord");
        let _this = this;
        //
        let _idVrste = idVrste;
        let _id = id;
        let _confirmationStatus = 1;
        let _komentar = komentar;
        let _idAkcije = idAkcije;
        //
        let _data = { idVrste: _idVrste, id: _id, confirmationStatus: _confirmationStatus, komentar: _komentar, idAkcije: _idAkcije, caller:"Grid"  };
        //
        let _urlAction = "";
        _urlAction = "LikvConfirmations/SetConfirmationstatusGrid";
        //
        let _saopServicesCore = new SaopServicesCore();
        _saopServicesCore.afterDone = function (response) {
            if (response.alertMessage) {
                alertify.message(response.alertMessage);
            }

            if (response.viewContent) {
                $("#messageBannerGrid").html(response.viewContent);
            }

            _this._likvCakalnaVrstaComment.CloseViewForm();

            _this.refreshGridData(0);

        };
        _saopServicesCore.ExecuteAction({
            action: _urlAction
            , data: _data
            , type: "POST"
        });
    }

    onDenyAndNext() {
        global.ConsoleLogDegug("LIKVCakalnaVrstaGrid.onDenyAndNext");
        let _idVrsteElement = $('#LikvConfirmationsEditView').find("#IDVrste");
        let _idVrste = _idVrsteElement.val().toString();
        let _idElement = $('#LikvConfirmationsEditView').find("#ID");;
        let _id = _idElement.val().toString();
        let _idAkcije = "-1";

        let _komentar = $("#AddCommentView").find("#Komentar")[0] as HTMLInputElement;
        if (this._likvCakalnaVrstaComment.onValideateKomentar(_komentar)) {
            this.denyRecordAndNext(_idVrste, _id, _komentar.value, _idAkcije);
        }

    }

    denyRecordAndNext(idVrste: string, id: string, komentar: string, idAkcije: string): void {
        global.ConsoleLogDegug("LIKVCakalnaVrstaGrid.denyRecordAndNext");
        let _this = this;
        //
        let _idVrste = idVrste;
        let _id = id;
        let _confirmationStatus = 1;
        let _komentar = komentar;
        let _idAkcije = idAkcije;

        let _data = { idVrste: _idVrste, id: _id, confirmationStatus: _confirmationStatus, komentar: _komentar, idAkcije: _idAkcije, caller:"Edit"  };
        //
        let _urlAction = "";
        _urlAction = "LikvConfirmations/SetConfirmationstatusGrid";
        //
        let _saopServicesCore = new SaopServicesCore();
        _saopServicesCore.afterDone = function (response) {
            if (response.alertMessage) {
                alertify.message(response.alertMessage);
            }

            if (response.viewContent) {
                $("#messageBannerEdit").html(response.viewContent);
            }

            _this._likvCakalnaVrstaComment.CloseViewForm();

            if (response.success == true)
                _this.MoveToNextExt(true);

            _this.refreshGridData(0);

        };
        _saopServicesCore.ExecuteAction({
            action: _urlAction
            , data: _data
            , type: "POST"
        });
    }

    refreshData(htmlContent: string) {
        global.ConsoleLogDegug("LIKVCakalnaVrstaGrid.refreshData");
        this.webGrid1.replaceGridRows(htmlContent);
        this.initWebGrid1Listners();
        this.setGlobalConfirmDenyButtonsEnableDisable();
        this.setCheckboxEnabledDisabled();
    }

    refreshGridData(year: number): void {
        global.ConsoleLogDegug("LIKVCakalnaVrstaGrid.refreshGridData");

        //        let applicationCode = <string>(this._vrstaDokumenta.selectedCB.val().toString());
        let applicationCode = this._izbranaVrstaDokumenta;

        let _this = this;
        let saopServicesCore = new SaopServicesCore();
        let params = { year: year, applicationCode: applicationCode };

        saopServicesCore.afterDone = function (response) {
            _this.refreshData(response);
        };
        saopServicesCore.ExecuteAction({
            action: "LikvConfirmations/Index"
            , data: params
            , type: "GET"
        });
    }

    editRecord(_vrstaDokumenta: string, _dokument: string, _idVrste: string, _id: string) {
        global.ConsoleLogDegug("LIKVCakalnaVrstaGrid.editRecord");
        //pridobimo id-je naslednje vrstice
        this.StorePrevAndNextRowData(_idVrste, _id);
        let _fullTitle = this._LIKVCakalnaVrstaView.translationService.translate("Potrjevanje") + ": " + _vrstaDokumenta + " " + _dokument;
        this._LIKVCakalnaVrstaView.fullTitle = _fullTitle;
        this._LIKVCakalnaVrstaView.showViewForm(SaopWfAction.wfaEditRecord, { queueId: _idVrste, threeId: _id, fullView: true });
    }

    initFilterControls(): void {
        global.ConsoleLogDegug("LIKVCakalnaVrstaGrid.initFilterControls");
        let _this = this;
        this._searchValue.keyup(function () {
            let oTable = _this.webGrid1.dataTable;
            oTable.search($(this).val().toString()).draw();
        });
        this._searchValue1.keyup(function () {
            let oTable = _this.webGrid1.dataTable;
            oTable.search($(this).val().toString()).draw();
        });
    }

    onDenyClick() {
        global.ConsoleLogDegug("LIKVCakalnaVrstaGrid.onDenyClick");
        let _row = $('#LIKVCakalnaVrstaGrid > tbody > tr').eq(this._selectedRowNo);
        if (_row.length > 0) {
            let _komentar = $("#AddCommentView").find("#Komentar")[0] as HTMLInputElement;
            if (this._likvCakalnaVrstaComment.onValideateKomentar(_komentar)) {
                let _idVrste = _row.attr("data-idvrste");
                let _id = _row.attr("data-id");
                let _idAkcije = "-1";
                this.denyRecord(_idVrste, _id, _komentar.value, _idAkcije);
            }
        }
    }

    onConfirmGlobalClick() {
        global.ConsoleLogDegug("LIKVCakalnaVrstaGrid.onConfirmGlobalClick");
        let rows_selected = this.webGrid1.getSelectedRowsV2();
        rows_selected.forEach(_el => {
            //console.log(_el);
            let _row = $('#LIKVCakalnaVrstaGrid > tbody > tr').eq(Number(_el)); // here we extrapolate the row data of selected index
            if (_row.length > 0) {
                //
                let _idVrste = _row.attr("data-idvrste");
                let _id = _row.attr("data-id");
                let _komentar = $("#AddCommentConfirmView").find("#Komentar").val();
                let _idAkcije = _row.attr("data-idAkcije");
                //
                //console.log(_idVrste);
                this.confirmRecord(_idVrste, _id, _komentar.toString(), _idAkcije, true);
            }

        });
    }

    onDenyGlobalClick() {
        global.ConsoleLogDegug("LIKVCakalnaVrstaGrid.onDenyGlobalClick");
        let rows_selected = this.webGrid1.getSelectedRowsV2();
        rows_selected.forEach(_el => {
            //console.log(_el);
            let _row = $('#LIKVCakalnaVrstaGrid > tbody > tr').eq(Number(_el)); // here we extrapolate the row data of selected index
            if (_row.length > 0) {
                let _komentar = $("#AddCommentView").find("#Komentar")[0] as HTMLInputElement;
                if (this._likvCakalnaVrstaComment.onValideateKomentar(_komentar)) {
                    let _idVrste = _row.attr("data-idvrste");
                    let _id = _row.attr("data-id");
                    let _idAkcije = _row.attr("data-idAkcije");
                    this.denyRecord(_idVrste, _id, _komentar.value, _idAkcije);
                }
            }

        });
    }

    onApplicationFilterChange(e: any): void {
        global.ConsoleLogDegug("LIKVCakalnaVrstaGrid.onApplicationFilterChange");
        let _applicationFilter = this._mainFormDiv.find("#ApplicationFilter");
        if (_applicationFilter.length > 0) {
            this._izbranaVrstaDokumenta = _applicationFilter.val() as string;
            if (this._izbranaVrstaDokumenta == "?")
                this._izbranaVrstaDokumenta = "";
        }
        this.refreshGridData(0);
    }

    // initVrstaDokumenta(): void {
    //   global.ConsoleLogDegug("LIKVCakalnaVrstaView.initVrstaDokumenta");

    //   this._vrstaDokumenta = new SaopMultiselect();
    //   this._vrstaDokumenta.afterChangeCommonEvent = this.filterVrstaDokumenta.bind(this);
    //   // init za metodo "afterChangeCommonEvent", da se že v "_vrstaDokumenta.init" metodi sprozi filterVrstaDokumenta
    //   this._vrstaDokumenta.init("#VrstaDokumentaFilter", "Vrsta dokumenta");
    //   // prikazemo select div
    //   $(this._vrstaDokumentaDivId).show();
    // }

    filterVrstaDokumenta(element: any) {
        global.ConsoleLogDegug("LIKVCakalnaVrstaView.filterVrstaDokumenta");

        this.refreshGridData(0);
    }

    afterPopoverMobileFilterShow(element: HTMLElement): void {
        global.ConsoleLogDegug("LIKVCakalnaVrstaView.afterPopoverMobileFilterShow");

        let atrContent = element.getAttribute("data-popover-content");
        // preverimo če je popup likv filter
        if (atrContent === "#popOverLikvFilter") {

            // this._vrstaDokumentaMobile = new SaopMultiselect();
            // this._vrstaDokumentaMobile.init("div.popover.show #VrstaDokumentaFilterMobile", "Vrsta dokumenta");
            // // metoda this.setSelectSkupinaMobileFilterValues se mora izvesti pred this._selectSkupinaMobileFilter.afterChangeCommonEvent
            // // da se se ne izvede metoda this.selectSkupinaMobileFilterChanged, ker se v this.setSelectSkupinaMobileFilterValues
            // // nastavijo vrednosti v klasu SaopMultiselect in posledicno se klice tudi afterChangeCommonEvent event
            // this.setSelectVDMobileFilterValues();   
            // this._vrstaDokumentaMobile.afterChangeCommonEvent = this.filterVrstaDokumentaMobileChanged.bind(this);

            let _ApplicationFilterMobile = $('div.popover.show').find('#ApplicationFilterMobile');
            if (_ApplicationFilterMobile.length > 0) {
                _ApplicationFilterMobile.off("change");
                _ApplicationFilterMobile.on("change", this.onApplicationFilterMobileChange.bind(this));
            }
            _ApplicationFilterMobile.val(this._izbranaVrstaDokumenta).change(); //prop('selected', true);

            // dodamo custom css za filter
            let filterPopoverDiv = $("div.popover.show");
            if (filterPopoverDiv != null) {
                let customCss = "calendar-filter";
                if (!filterPopoverDiv.hasClass(customCss)) {
                    filterPopoverDiv.addClass(customCss);
                }
            }

            // dodamo custom css za filter
            let filterPopoverBodyDiv = $("div.popover.show").find("div.popover-body");
            if (filterPopoverBodyDiv != null) {
                let customCss = "calendar-filter-body";
                if (!filterPopoverBodyDiv.hasClass(customCss)) {
                    filterPopoverBodyDiv.addClass(customCss);
                }
            }

            // prikazi filter okno
            let filterBody = $("div.popover.show").find("#popOverLikvFilterBody");
            filterBody.show();
        }
    }

    setSelectVDMobileFilterValues() {
        global.ConsoleLogDegug("LIKVCakalnaVrstaView.setSelectVDMobileFilterValues");
        // preberemo vrednosti VD iz desktop Category element
        let izbranaVrstaDokumenta = <string[]>(this._vrstaDokumenta.selectedCB.val());
        this._vrstaDokumentaMobile.setSelectValues(izbranaVrstaDokumenta);
    }

    filterVrstaDokumentaMobileChanged(element: any) {
        global.ConsoleLogDegug("LIKVCakalnaVrstaView.filterVrstaDokumenta");

        // preberemo, VD in jih shranimo v desktop Category element
        let izbranaVrstaDokumenta = <string[]>(element.val());
        this._vrstaDokumenta.setSelectValues(izbranaVrstaDokumenta);

        this.refreshGridData(0);
    }

    setGlobalConfirmDenyButtonsEnableDisable(): void {
        global.ConsoleLogDegug("LIKVCakalnaVrstaGrid.webGridAfterColumnCheckboxChanged");

        let _btnConfirmGlobal = this._mainFormDiv.find("#btnConfirmGlobal");
        _btnConfirmGlobal.prop('disabled', true);
        let _btnDenyGlobal = this._mainFormDiv.find("#btnDenyGlobal");
        _btnDenyGlobal.prop('disabled', true);

        $("#LIKVCakalnaVrstaGrid tr td.dt-checkboxes-cell input[type='checkbox'].dt-checkboxes").each(function () {
            let checked = $(this).is(":checked");
            if (checked) {
                _btnConfirmGlobal.prop('disabled', false);
                _btnDenyGlobal.prop('disabled', false);
            }
        });
    }

    switchSignees() {
        global.ConsoleLogDegug("LIKVCakalnaVrstaGrid.switchSignees");
        let _this = this;
        let _data = {};
        //
        let _urlAction = "";
        _urlAction = "LikvConfirmations/SwitchSignees";
        //
        let _saopServicesCore = new SaopServicesCore();
        _saopServicesCore.afterDone = function (response) {
            if (response.alertMessage) {
                alertify.message(response.alertMessage);
            }

            if (response.viewContent) {
                $("#messageBanner").html(response.viewContent);
            }

            _this.refreshGridData(0);

        };
        _saopServicesCore.ExecuteAction({
            action: _urlAction
            , data: _data
            , type: "POST"
        });

    }

    public onLIKVidDocumentComplete(): void {
        global.ConsoleLogDegug("LIKVCakalnaVrstaView.onDenyDocumentComplete");

        this.refreshGridData(0);
    }

    setCheckboxEnabledDisabled(): void {
        global.ConsoleLogDegug("LIKVCakalnaVrstaGrid.setCheckboxEnabledDisabled");
        $("#LIKVCakalnaVrstaGrid tr").each(function () {
            let _id = $(this).attr("data-rowID");
            let _row = $('#LIKVCakalnaVrstaGrid > tbody > tr').eq(Number(_id));
            if (_row.length > 0) {
                let _idAkcije = _row.attr("data-idAkcije");
                let _checkbox = _row.find("td.dt-checkboxes-cell input[type='checkbox'].dt-checkboxes");
                if (_idAkcije == "2")
                    _checkbox.prop('disabled', false)
                else
                    _checkbox.prop('disabled', true);
            }
        });
    }

    onApplicationFilterMobileChange(e: any): void {
        global.ConsoleLogDegug("LIKVCakalnaVrstaGrid.onApplicationFilterMobileChange");
        let _applicationFilter = $('div.popover.show').find('#ApplicationFilterMobile');
        if (_applicationFilter.length > 0) {
            this._izbranaVrstaDokumenta = _applicationFilter.val() as string;
            if (this._izbranaVrstaDokumenta == "?")
                this._izbranaVrstaDokumenta = "";
        }
        this.refreshGridData(0);
    }

    private setMobileView(): void {
        global.ConsoleLogDegug("LIKVCakalnaVrstaGrid.setMobileView");
        if (this.isMobileView()) {
            this._mainFormDiv.find('#grid-toolbar').addClass("mobile-gridtoolbar");
            this._mainFormDiv.find('#gridtoolbarRow').addClass("mobile-gridtoolbarRow");
            this._mainFormDiv.find('#mobileSearchValueDiv').addClass("mobile-SearchValueDiv");
            this._mainFormDiv.find('#mobileSearchValueDiv .saop-img-search').addClass("mobile-DropDownImgSearch");
            this._mainFormDiv.find('#mobileDropDownDiv').addClass("mobile-DropDownDiv");
            this._mainFormDiv.find('#LIKVCakalnaVrstaGrid').toggleClass("table-column-holder-last");
        }
    }

    private isMobileView(): boolean {
        global.ConsoleLogDegug("LIKVCakalnaVrstaGrid.");
        let isMobile = this._mainFormDiv.find('#mobileSearchValueDiv').css("display") != "none";

        return isMobile;
    }

    editRowFromDashboard(): void {
        global.ConsoleLogDegug("LIKVCakalnaVrstaGrid.editRowFromDashboard");
        let _this = this;
        if (!this._dashboard) {
            //poberemo vrstico ki ima atribut data-rowselected = true, natavimo index, jo oznacimo in odpremo edit formo
            let _rowSelected = "True";
            let data = $("tr[data-rowselected='" + _rowSelected + "']");
            if (data.length > 0) {
                let _idVrste = data.attr("data-IDVrste");
                let _id = data.attr("data-ID");
                let _vrstaDokumenta = data.attr("data-vrstadokumenta");
                let _dokument = data.attr("data-dokument");
                _this._selectedRowNo = parseInt(data.attr("data-rowID")); // here we store the ID of the current row

                if (!isUnDefined(_idVrste) && !isUnDefined(_id)) {
                    // barvanje oznacene vrstrice
                    _this.webGrid1.markSelectedRow(<HTMLElement>(data)[0]);

                    _this.editRecord(_vrstaDokumenta, _dokument, _idVrste, _id);
                }
            }
        }
    }

    private StorePrevAndNextRowData(_idVrste:string, _id:string): void {
        global.ConsoleLogDegug("LIKVCakalnaVrstaGrid.StoreNextRow");
        if (_idVrste.length > 0) {
            this._prevIDVrste = "";
            this._prevID = "";     
            this._nextIDVrste = "";
            this._nextID = "";        
            let _row = $("tr[data-IDVrste='" + _idVrste + "']").filter("tr[data-ID='" + _id + "']");
            if (_row.length > 0) {
                let _nextTR = _row.next("tr");
                if (_nextTR.length > 0){
                    this._nextIDVrste = _nextTR.attr("data-idvrste");
                    this._nextID = _nextTR.attr("data-id");
                }
                let _prevTR = _row.prev("tr");
                if (_prevTR.length > 0){
                    this._prevIDVrste = _prevTR.attr("data-idvrste");
                    this._prevID = _prevTR.attr("data-id");                
                }
            }
        }
    }

}