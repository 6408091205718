import { global, getRelativeSiteURL, getSiteAbsoluteURL, isEmpty, isUnDefined } from "../Common/saop-common";
import { SaopViewForm } from "../Core/saop-view-form";
import { SaopUploadFiles } from "../Components/saop-upload-files";
import { SaopAttachmentList } from "../Components/saop-attachment-list";
import { OPNPotniNalogiGoggleMapsView } from "../OPN/OPNPotniNalogiGoggleMaps-view";
import { SaopMessageDlg, SaopMsgDlgButton } from '../Components/saop-message-dlg';
import { SaopServicesCore } from "../Core/saop-services-core";
import { SaopFloatingLabel } from '../Components/saop-floating-label';


export class LIKVCakalnaVrstaComment extends SaopViewForm {
    private _formId: string;
    private _saopFloatingLabel: SaopFloatingLabel = new SaopFloatingLabel();


    constructor(viewId: string, libName: string = "") {
        super(viewId, libName);
        this.title = this.translationService.translate("Zavrni dokument");
        this.fullTitle = this.title;
        this.addRecordEndpoint = "";
        this.editRecordEndpoint = "LikvConfirmations/AddComment";
        this.deleteRecordEndpoint = "";
        //
        this.afterViewShowSuccess = this.initView;
        this.afterSubmitSuccess = this.initView;
        //
    }

    setDictionary() {
        global.ConsoleLogDegug("LIKVCakalnaVrstaComment.setDictionary");
        let dictionary: string[] = ["Potrjevanje: prejeti račun", "Razlog zavrnitve je obvezen podatek."];
        this.setTerminologyList(dictionary);
    }


    public initView(): void {
        global.ConsoleLogDegug("LIKVCakalnaVrstaComment.initView");
        this.setDictionary();
        this.LoadViewEvents();
    }

    public LoadViewEvents(): void {
        global.ConsoleLogDegug("LIKVCakalnaVrstaComment.LoadViewEvents");
        let _viewDiv = $(this.id);
        if (_viewDiv.length == 0) { return; }

        let _this = this;
        this._formId = "#mainLikvCakalnaVrstaComment";

        $("#AddCommentView").find("#btnDeny").off("click");
        $("#AddCommentView").find("#btnDeny").on('click', function (row) {
            _this.onDenyClick();
        });
    }

    public onDenyClick(): void {
        global.ConsoleLogDegug("LIKVCakalnaVrstaComment.onDenyClick");
        let _komentar = $("#AddCommentView").find("#Komentar")[0] as HTMLInputElement;
        let _komentarText = _komentar.value;
        if (this.onValideateKomentar(_komentar)) {

            global.ConsoleLogDegug("LIKVCakalnaVrstaComment.onDenyClick.submit");
            this.CloseViewForm();
            $("#LikvConfirmationsEditView").find("#desktopKomentar").val(_komentarText);
            $("#LikvConfirmationsEditView").find("#mobileKomentar").val(_komentarText);
            $("#LikvConfirmationsEditView").find("#Status").val(1);
            $("#LikvConfirmationsEditView").submit();
        }
    }

    public onValideateKomentar(komentar: HTMLInputElement): boolean {
        global.ConsoleLogDegug("LIKVCakalnaVrstaComment.onValideateKomentar");

        let _komentarText = komentar.value;
        if (_komentarText.length == 0) {
            let _msgRequred = this.translationService.translate("Razlog zavrnitve je obvezen podatek.");
            this._saopFloatingLabel.setValidationError(komentar, _msgRequred);
            return false;
        }
        return true;
    }

    //
}

